import React, { useState, useCallback, useEffect, useRef } from 'react'
import OpenAI from "openai";

const openai = new OpenAI({ apiKey: process.env.REACT_APP_GPT_SECRET_KEY, dangerouslyAllowBrowser: true });

async function makeRequest(_message) {
    const message = "Something went wrong, please try again"
  
    try {
      const completions = await openai.chat.completions.create({
        messages: [{ role: "system", content: _message }],
        model: "gpt-3.5-turbo",
        temperature: .2,
      });
  
      return completions.choices[0]?.message?.content || message
    } catch (e) {
      console.log(e)
      return message
    }
  }
  
  function Message(props) {
    return (
      <li
        className={"isSender" + props.isSender}
      >
          <span style={{fontWeight:700,color:'black'}}> {props.isSender ? 'You' : 'WanderAI'}</span> <br />
          {props.message || "Hello, how can I help you?"} <br /><br />
      </li>
    )
  }

function Chat({input}) {
    const [message, setMessage] = useState("")
    const [messageList, setMessageList] = useState([])
    const lastMessageRef = useRef(null)
    const inputRef = useRef(null)
    const [loading, setLoading] = useState(false)

    useEffect(function onMount() {
        // Load message history. Not NA for our case now

        setTimeout(async () => {
        inputRef.current?.focus()

        if (input) {
            addMessage(input, true)
            const response = await makeRequest(input)
            addMessage(response, false)
        }
        
        }, 300)
    }, [])

    function addMessage(message, isSender) {
        setMessageList(list => {
        return [...list, { message, isSender }]
        })
    }

    async function handleSearch(e) {
        e.preventDefault();
        if (message.trim()) {
        setLoading(true)
        setMessage("")
        addMessage(message, true) // add sending message

        const result = await makeRequest(`In a friendly, conversational, professional tone. ${message}`)
        setLoading(false)
        addMessage(result || "", false) // add receiving message
        } else {
        // Handle invalid message
        }

        setTimeout(() => {
        inputRef.current?.focus()
        }, 100)
    }

  return (
    <div class="general">
         <div id='chatBox'>
            <ul>
            {messageList?.map(({ message, isSender }, i) => {
                return (
                <Message
                    key={i}
                    message={message}
                    isSender={isSender}
                />
                )
            })}

            {loading && (
                <li>
                One moment I find the answer...
                </li>
            )}

            <li className="invisible block" ref={lastMessageRef}>
                .
            </li>
            </ul>
         </div>
         <form>
                <input type="text" value={message} onInput={e => setMessage(e.target.value)}></input>
                <button type="submit" onClick={(e) => handleSearch(e)}><i class="fa fa-solid fa-paper-plane"></i></button>
            </form>
    </div>
   
    )
}

export default Chat;
