import '../App.css';
import React, { useState } from 'react';

function App() {
  const [showWaitlistLanding, setShowWaitlistLanding] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignUpConfirmation, setShowSignUpConfirmation] = useState(false);

  const handleSubmit = (e) => {
    const formEle = document.querySelector('form');
    e.preventDefault();
    const formData = new FormData(formEle);
    fetch("https://script.google.com/macros/s/AKfycbybpqAVu-EPUcZPMMIwJ5nTKF-KaCxQnSP0PjnSKK386BCK5GAsMrm6xwhQtSzDVjAuHA/exec", {
      method: "POST",
      body: formData,
      mode: 'no-cors'
    });
    goToSignUpConfirmation();
  }

  const hideDiv = (divName) => {
    if (divName === "waitlistLanding") {
      setShowWaitlistLanding(false);
      setShowSignUp(true);
    } else if (divName === "signUp") {
      setShowSignUp(false);
      setShowSignUpConfirmation(true);
    }
  }

  const goToSignUp = (e) => {
    e.preventDefault();
    return hideDiv('waitlistLanding')
  }

  const goToSignUpConfirmation = () => {
    return hideDiv('signUp')
  }

  return (
    <div id="comingSoon">
      <header className="header">
        <div className="pageBanner"><h1>WanderAI</h1></div>
      </header>
      <body>
        <div className="background"></div>
        <div className="comingSoon">
          <div className="waitlistLanding" style={showWaitlistLanding ? {} : { display: 'none'}}>
            <h2>Design unforgettable vacations with WanderAI<br/>Your AI-powered assistant for planning luxury travel</h2>
            <button onClick={goToSignUp} className="joinWaitlistButton">Join the waitlist</button>
          </div>
          <div className="signUp" style={showSignUp ? {} : { display: 'none'}}>
            <h2>
              Sign up now to be one of WanderAI's beta users
            </h2>
            <p>Are you ready to level up your travel planning process? It’s easy! Sign up with your email and we will reach out to you when WanderAI is ready to use.</p>
            <form className='form' onSubmit={(e) => handleSubmit(e)}>
                <input required type="email" name="Email" placeholder='Enter your email address'/>
              <button type="submit" >Sign up</button>
            </form>
            <p>By signing up to be a beta user with your email, you consent to receive emails from WanderAI.</p>
          </div>
          <div className="signUpConfirmation" style={showSignUpConfirmation ? {} : { display: 'none'}}>
            <h2>Thank you for signing up.</h2>
            <p>We can't wait to share WanderAI with you!
            <br /><br />To learn more about the project, please reach out to us at <a href="mailto:hi@wanderwai.com">hi@wanderai.com</a>
            </p>
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
