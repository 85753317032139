import '../App.css';
import React, { useState } from 'react';
import Chat from './Chat';
import Main from './Main';

function Home() {
    const [openChatWindow, setOpenChatWindow] = useState(false)
    const [message, setMessage] = useState('')

    function handleSubmit(e) {
        e.preventDefault();
        setOpenChatWindow(true); 
    }

    function handleReturn(e) {
        e.preventDefault();
        setOpenChatWindow(false);
    }

    return (
        <div id='home'>
            <header className="header">
                <div className="pageBanner">
                    <h1 onClick={(e) => handleReturn(e)}>WanderAI</h1>
                </div>
            </header>

            <container>
                <nav>
                    <button>New Chat</button>
                    <h6>
                        <i class="fa fa-thinlight fa-location-dot" />  Destinations
                    </h6>
                    <ul>
                        <li><a href='/'>+ Add Destination</a></li>
                    </ul>
                    <h6>
                        <i class="fa fa-solid fa-bed" />  Hotels
                    </h6>
                    <ul>
                        <li><a href='/'>+ Add Hotel</a></li>
                    </ul>
                    <h6>
                        <i class="fa fa-thin fa-handshake" />  Partners
                    </h6>
                    <ul>
                        <li><a href='/'>+ Add Partner</a></li>
                    </ul>
                    <h6>
                        <i class="fa fa-thin fa-person" />  Clients
                    </h6>
                    <ul>
                        <li><a href='/'>+ Add Client</a></li>
                    </ul>
                </nav>

                <body>
                    <div class="landingWindow">
                        { openChatWindow ? <Chat input={message} /> : <Main /> }
                    <form style={openChatWindow ? { display: 'none'} : {}}>
                        <input type="text" placeholder='What hotels in Tuscany have a kids club?' onInput={e => setMessage(e.target.value)}></input>
                        <button type="submit" onClick={(e) => handleSubmit(e)}><i class="fa fa-solid fa-paper-plane"></i></button>
                    </form>
                    </div>
                </body>
            </container>
        </div>
    )
};

export default Home;
