import paris from '../Assets/Paris.png';
import japan from '../Assets/Japan.png';
import egypt from '../Assets/Egypt.png';
import portugal from '../Assets/Portugal.png';
import newVisit from '../Assets/New.png';

function Main() {

  return (
    <div class="landingWindow">              
      <h3>Hi, how can I help you today?</h3>
      <h4>Unsure what to ask? Let us help you get started!</h4>
      <section>
          <div class='box'>
              <h6>Find an accomodation</h6>
              <ul>
                  <li>What boutique hotels in Rome feel romantic for honeymooners?</li>
                  <li>What hotels in Sicily receive Virtuoso amenities?</li>
                  <li>What hotels in Venice have family or connecting rooms?</li>
              </ul>
          </div>
          <div class='box'>
              <h6>Learn more about a destination</h6>
              <ul>
                  <li>What is the best time to visit the Dolomites, and a suggested sample hiking program?</li>
                  <li>What are the main tourist attractions in Bologna?</li>
                  <li>Apart from Lake Como, what are the best lakes in Italy to visit in the summer?</li>
              </ul>
          </div>
          <div class='box'>
          <h6>Learn more about a hotel or partner</h6>
              <ul>
                  <li>What room configurations at Hotel de Russie are best for a family of 5?</li>
                  <li>What DMCs in Italy will work on tours and transfers only, and provide a cost breakdown?</li>
                  <li>Does the Four Seasons Hotel in Taormina offer 24/7 room service?</li>
              </ul>
          </div>
          <div class='box'>
              <h6>Plan an itinerary</h6>
              <ul>
                  <li>Help me plan an itinerary for a client visiting...</li>
              </ul>
              <div className='itineraries'>
                  <div className='itinerary'><img src={paris} alt="paris"/>Paris</div>
                  <div className='itinerary'><img src={japan} alt="japan"/>Japan</div>
                  <div className='itinerary'><img src={egypt} alt="egypt"/>Egypt</div>
                  <div className='itinerary'><img src={portugal} alt="portugal"/>Portugal</div>
                  <div className='itinerary'><img src={newVisit} alt="new" style={{opacity: 0.5}}/>New</div>
              </div>
          </div>
          <br/>
      </section>
    </div>
    )
}

export default Main;
